<template>
    <section class="d-flex flex-column p-1 px-2 container mt-2">
        <div v-if="loading" class="d-flex justify-content-center align-items-center">
            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            <label class="mt-1">Carregando empresas...</label>
        </div>
        <div v-else id="container">
            <div class="d-flex flex-row justify-content-between align-items-center mt-2 mb-2"><label>Selecionadas:</label>
                <div class="button-select-all">
                    <button type="button" class="btn btn-sm btn-primary mr-2 button-select" @click.prevent="selectAll()">
                        {{ selectedAll ? "Deselecionar todas" : "Selecionar todas" }}
                    </button>
                </div>
            </div>
            <hr class="mb-2 mt-0"/>
            <div class="list-companies">
                <div v-for="company in selectedCompanies" :id="`data-` + company._id" class="item-company" :key="company._id" @click="deselectCompany(company)">
                    <i class="fas fa-check-square text-primary ml-3"></i>
                    <label class="font-weight-normal ml-2 mt-2" :for="company._id">{{company.name}}</label>
                    <hr class="mb-0 mt-0"/>
                </div>
            </div>
            <label class="d-flex flex-row justify-content-between align-items-center mt-2">Empresas:
                <div class="search-bar">
                    <input
                        @keyup="getFilterCompanies()"
                        v-model="searchCompanies"
                        title="Pesquisar Empresa"
                        type="text"
                        name="table_search"
                        class="form-control float-right input-search"
                        :placeholder="`Pesquisar`"
                    />
                    <i
                        class="fas fa-search lupa-style"
                        @click="getFilterCompanies()"
                        title="Perquisar empresa"
                    ></i>
                </div>
            </label>
            <div class="list-companies">
                <div v-for="company in companies" :id="`data-` + company._id" class="item-company" :key="company._id" @click="selectCompany(company)">
                    <i class="far fa-square ml-3"></i>
                    <label class="font-weight-normal ml-2 mt-2" :for="company._id">{{company.name}}</label>
                    <hr class="mb-0 mt-0"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
  props: {
      companies_notification: {
          type: Array,
          default: []
      }
    },
  data () {
    return {
        companies: [],
        selectedCompanies: [],
        companiesGroup: [],
        searchCompanies: '',
        loading: false,
        selectedAll: false,
        timeoutId: null
    }
  },

  mounted() {
    this.getCompanies();
  },

  methods: {
    /**
    * Metodo para selecionar todas as empresas
    */
    selectAll() {
        if (!this.timeoutId) {
            this.timeoutId = setTimeout(() => {
                this.timeoutId = null;
            }, 100);

            if (this.selectedAll) {
                this.companies = this.companiesGroup;
                this.selectedCompanies = [];
            } else {
                this.selectedCompanies = this.companiesGroup;
                this.companies = [];
            }

            this.selectedAll = !this.selectedAll
            this.$emit('selected_companies', this.selectedCompanies);
        }
    },
    /**
    * Metodo para selecionar empresas
    */
    selectCompany(company) {
        if (!this.timeoutId) {
            this.timeoutId = setTimeout(() => {
                this.timeoutId = null;
            }, 100);

            this.setCompanies(company);
        }
    },
    /**
     * Método para atualizar o objeto das empresas
     * @param {Object} company Objeto da empresa
     * @param {Boolean} select Ação Selecionar/Deselecionar true/false
     */
    setCompanies(company, select = true) {
        if (select) {
            var companyIsInside = this.selectedCompanies.find(item => item._id == company._id);
    
            if (!companyIsInside) {
                this.selectedCompanies.push(company);
                this.companies = this.companies.filter(item => item._id != company._id);
                this.$emit('selected_companies', this.selectedCompanies);
            }
        } else {
            var companyIsInside = this.companies.find(item => item._id == company._id);

            if (!companyIsInside) {
                this.companies.push(company);
                this.selectedCompanies = this.selectedCompanies.filter(item => item._id != company._id)
                this.$emit('selected_companies', this.selectedCompanies);
            }
        }
    },
    /**
    * Metodo para deslececionar empresas
    */
    deselectCompany(company) {
        if (!this.timeoutId) {
            this.timeoutId = setTimeout(() => {
                this.timeoutId = null;
            }, 100);

            this.setCompanies(company, false);
        }
    },
    /**
    * Metodo para buscar as empresas no banco
    */
    getCompanies() {
        this.loading = true;
        this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`
            
        this.$tallos_admin_api.get('companies/all-name')
            .then(res => {
                this.companies = res.data;
                this.companiesGroup = res.data;
                this.loading = false;

                if (this.companies_notification) {
                    this.mountCompaniesNotification();
                }
            })
            .catch(e => {
                this.$toasted.global.defaultError({msg: 'Erro ao buscar companhias.'})
            })
    },
    /**
    * Metodo para buscar as empresas sem consultar
    */
    getFilterCompanies() {
      if (this.searchCompanies == "") {
        this.companies = this.companiesGroup;
      } else {
        this.companies = this.companiesGroup.filter(company => {
            return ( company.name.match(new RegExp(this.searchCompanies, "i")) );
        });
      }
    },
    /**
    * Metodo para montar as empresas sem consultar
    */
    mountCompaniesNotification: async function() {
        for (let company of this.companies_notification) {
            this.setCompanies(company);
        }
    }
  },
}
</script>

<style scoped>
.container {
    background-color: #f1f1f1;
    height: 300px;
    width: 500px;
    padding-top: 30px;
    overflow-y: auto;
    align-content: center;
    border-radius: 8px;
}

.lupa-style {
    position: relative;
    right: -240px;
    top: 6px;
    font-size: 14px;
    color: #428bca;
    cursor: pointer;
}

.input-search {
    border-radius: 20px !important;
    width: 250px;
    height: 35px;
    background-color: #fff;
    color: #428bca;
}

.input-search::placeholder {
    font-size: 15px;
}

.search-bar {
    position: relative;
}

.button-select-all {
    position: relative;
}

.list-companies {
    background-color: #FFFFFF;
}

.item-company:hover {
    background-color: #f1f1f1;
}

.button-select {
    border-color: #fff;
    background-color: #428bca;
    color: #fff;
    border-radius: 8px;
}

.button-select:hover {
    border-color: #fff;
    background-color: #1a67ac;
    color: #fff;
}

/* width */
::-webkit-scrollbar {
    height: 5px !important;
    width: 8px;
    border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #dbdbdb; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b5b5b5; 
}
</style>